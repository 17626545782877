<template>
  <v-container id="test" fluid tag="section">
    <v-row>
      <v-col cols="auto">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn color="success" min-width="100" @click="validate"
            >Validate
          </v-btn>

          <approver-select-dialog
            :disabled="!requestEnabled"
            :approvers="approvers"
            :reviewers="reviewers"
            :request-callback="requestCallback"
          ></approver-select-dialog>
        </v-card-actions>
      </v-col>
    </v-row>

    <v-textarea
      v-model="permissionText"
      auto-grow
      hint="Enter permission JSON"
      label="Permission JSON"
      class="code"
      @change="onChange"
    ></v-textarea>

    <v-snackbar v-model="snackbar" top right>
      {{ notificationMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import ApproverSelectDialog from "@/views/dashboard/pages/admin/ApproverSelectDialog";
import constants from "./constants";
import store from "@/store";

const ViewID = constants.VIEW_PERMISSION;
const Actions = {
  approve: "admin:Permission:Approve",
  request: "admin:Permission:Request",
  review: "admin:Permission:Review",
};

export default {
  components: { ApproverSelectDialog },
  data: () => ({
    permissionText: "{}",
    validated: false,
    snackbar: false,
    notificationMessage: "",
    approvers: [],
    reviewers: [],
    permissions: [],
  }),
  computed: {
    requestEnabled() {
      return this.validated && this.permissions.indexOf(Actions.request) >= 0;
    },
  },
  mounted() {
    Promise.all([
      this.loadCurrentPermissions(),
      this.getPermissions(),
      this.loadActionApprovers(),
    ]);
  },
  methods: {
    /**
     * 현재 권한 설정을 로드한다.
     */
    async loadCurrentPermissions() {
      try {
        const res = await this.authGet("/api/auth/perm");
        const data = res.data.data;
        if (data) {
          this.permissionText = data;
        }
      } catch (e) {
        this.processError(e);
      }
    },

    /**
     * 현재 화면에 대한 권한을 로드한다.
     */
    async getPermissions() {
      try {
        // 백엔드에서 요청한 현재 화면에서 허용된 액션 목록
        const res = await this.authGet(`/api/auth/perm/views/${ViewID}`);
        this.permissions = res.data.data || [];

        // Store에 저장된 현재 화면에서 허용된 액션 목록
        const allowedActions = store.getters.getViewAllowedActions(ViewID);

        console.log("permissions: ", this.permissions);
        console.log("allowedActions: ", allowedActions);

        // TODO: 페이지가 마운트될 때마다 권한을 읽어올지, Store에 저장된 권한을 읽어올 지 선택 필요
      } catch (e) {
        this.processError(e);
      }
    },

    /**
     * 승인/회람 가능한 어드민 사용자 목록을 로드한다.
     */
    async loadActionApprovers() {
      try {
        const res = await this.authGet("/api/auth/perm/approvers", {
          params: {
            approveAction: Actions.approve,
            reviewAction: Actions.review,
            viewId: ViewID,
          },
        });
        const { approvers, reviewers } = res.data.data;
        this.approvers = approvers || [];
        this.reviewers = reviewers || [];
      } catch (e) {
        this.processError(e);
      }
    },

    async validate() {
      // check valid json format
      const permObj = this.parseJson(this.permissionText);
      if (!permObj) {
        this.validated = false;
        return;
      }

      // server-side validation
      try {
        const res = await this.authPost("/api/auth/perm/validate", {
          json: this.permissionText,
        });
        const { valid, error } = res.data.data;
        this.validated = valid;
        if (error) {
          this.showNotification(`validation error: ${error}`);
        } else {
          this.showNotification("validation OK");
        }
      } catch (e) {
        this.processError(e);
        this.validated = false;
      }
    },

    /**
     * Parse input JSON. Show notification on error
     * @param json JSON string to parse
     * @returns {null|any} Parsed object. null on error.
     */
    parseJson(json) {
      try {
        return JSON.parse(json);
      } catch (e) {
        this.showNotification(e);
        console.error(e);
        return null;
      }
    },

    /**
     * 권한 변경 요청을 서버로 전송
     * @returns {Promise<boolean>} 권한 변경 요청 성공시 true, 에러가 발생한 경우 false
     */
    async requestCallback(approvers, reviewers) {
      try {
        await this.authPost("/api/auth/perm/request", {
          json: this.permissionText,
          approvers,
          reviewers,
        });
        this.showNotification("권한 변경을 요청했습니다.");
        return true;
      } catch (e) {
        this.showNotification(e);
        return false;
      }
    },

    onChange() {
      this.validated = false;
    },

    processError(e) {
      const { response } = e;
      if (response) {
        this.showNotification(response.data);
      } else {
        console.error(e);
        this.showNotification(e);
      }
    },

    showNotification(msg) {
      this.notificationMessage = msg;
      this.snackbar = true;
    },
  },
};
</script>

<style>
.code {
  font-family: monospace;
}
</style>
