<template>
  <div>
    <v-dialog v-model="dialog" name="approverSelectDialog" width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="warning"
          min-width="100"
          :disabled="disabled"
          @click="dialog = true"
          >Request
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2"> Request </v-card-title>
        <v-card-text>
          <v-row style="flex-wrap: nowrap">
            <v-col cols="auto">
              <div>
                Approvers:
                <v-checkbox
                  v-for="item in approvers"
                  :key="item.id"
                  v-model="selectedApprovers"
                  :label="`${item.name} (${item.email})`"
                  :value="item.id"
                  :disabled="item.disabled"
                  dense
                ></v-checkbox>
              </div>
            </v-col>
            <v-col cols="auto">
              <div>
                Reviewers:
                <v-checkbox
                  v-for="item in reviewers"
                  :key="item.id"
                  v-model="selectedReviewers"
                  :label="`${item.name} (${item.email})`"
                  :value="item.id"
                  :disabled="item.disabled"
                  dense
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="onCancel">Cancel</v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!requestEnabled"
            @click="onRequest"
            >Request</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ApproverSelectDialog",
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
    approvers: {
      type: Array,
      default: () => [],
    },
    reviewers: {
      type: Array,
      default: () => [],
    },
    requestCallback: {
      type: Function,
      default: undefined,
    },
  },
  data: () => ({
    dialog: false,
    selectedApprovers: [],
    selectedReviewers: [],
  }),
  computed: {
    requestEnabled() {
      return this.selectedApprovers.length > 0;
    },
  },
  watch: {
    selectedApprovers(val) {
      // 선택한 승인권자는 회람 대상에서 제외
      this.reviewers.forEach(
        (reviewer) => (reviewer.disabled = val.indexOf(reviewer.id) >= 0)
      );
    },
    selectedReviewers(val) {
      // 선택한 회람 대상자는 승인 대상에서 제외
      this.approvers.forEach(
        (approver) => (approver.disabled = val.indexOf(approver.id) >= 0)
      );
    },
  },
  methods: {
    onCancel() {
      this.dialog = false;
    },
    async onRequest() {
      if (this.requestCallback) {
        const ok = await this.requestCallback(
          this.selectedApprovers,
          this.selectedReviewers
        );
        if (ok) {
          this.dialog = false;
        }
      }
    },
  },
};
</script>
